import { useAuth } from './AuthContext';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    // Vous pouvez afficher un spinner de chargement ici ou simplement retourner null
    return null; // ou <LoadingSpinner />
  }

  if (!isAuthenticated()) {
    // Affichez le toast après la redirection ou configurez-le pour s'exécuter après un délai
    setTimeout(() => toast.error("Vous n'êtes pas authentifié !"), 100);
    return <Navigate to="/" replace />;
  }

  return children;
};


export default ProtectedRoute;