import React from 'react';
import { Helmet } from 'react-helmet-async';

const Section = ({ title, description }) => (
    <div className="w-full flex flex-col gap-y-4 rounded overflow-hidden bg-white pb-3 dark:bg-gray-800 shadow border dark:border-gray-900">
        <div className="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
            <div className="flex flex-col justify-center text-sm">
                <p className="font-semibold text-black dark:text-gray-300">{title}</p>
            </div>
        </div>
        <section className="h-full flex flex-col px-3">
            <p className="mb-2 dark:text-gray-300">{description}</p>
        </section>
    </div>
  );

const ForParents = () => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <html lang="fr" className="app dark" />
        <title>Citiizen - Pour les Parents</title>
      </Helmet>
      <main className="overflow-hidden site-bg">
        <div className="mx-auto mt-10 grid max-w-7xl grid-cols-12 gap-x-3 gap-y-8 p-6 md:mt-0">
          <div className='col-span-12 flex flex-col gap-y-3'>
            <div className="flex flex-col gap-y-6">
              <div className="mb-6 lg:justify-between md:mb-8">
                <h2
                className="max-w-lg font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none group">
                <span className="inline-block mb-1 sm:mb-4">
                Guide pour les Parents
                </span>
                <div
                    className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
            </h2>
              </div>

              <Section title="Âge Minimal et Contenu Sensible" description="Citiizen est conçu pour les joueurs âgés de 15 ans et plus. Bien que nous ne demandions pas la date de naissance à l'inscription, nous conseillons fortement aux parents de veiller à ce que leurs enfants respectent l'âge minimal requis. Le jeu peut contenir des références à des sujets sensibles tels que les armes à feu, la drogue et l'alcool, ainsi que des discussions sur des sujets sensibles entre utilisateurs." />

              <Section title="Supervision Parentale" description="Nous encourageons les parents à superviser l'activité de leurs enfants sur Citiizen. Comprendre avec qui votre enfant interagit et les sujets de discussion peut aider à prévenir l'exposition à des contenus inappropriés." />

              <Section title="Fonctionnalités de Blocage et de Signalement" description="Les utilisateurs peuvent bloquer d'autres joueurs pour éviter d'afficher leurs bulles de tchat et pour prévenir le harcèlement. En cas de litige ou de comportement inapproprié, nous recommandons de contacter immédiatement le service client de Citiizen pour une action rapide et appropriée." />

              <Section title="Conseils de Sécurité" description="Encouragez vos enfants à ne jamais partager d'informations personnelles, à utiliser des pseudonymes sans lien avec leur vrai nom, et à changer régulièrement de mot de passe pour renforcer la sécurité de leur compte." />

              <Section title="Dialogue Ouvert" description="Nous recommandons aux parents d'avoir un dialogue ouvert avec leurs enfants sur les expériences vécues dans le jeu, les interactions avec d'autres joueurs et la manière de gérer d'éventuelles situations désagréables ou de harcèlement en ligne." />

              <Section title="Éducation aux Médias Numériques" description="Citiizen peut servir de plateforme pour éduquer les enfants à une utilisation responsable et critique des médias numériques, en soulignant l'importance du respect en ligne et de la prudence dans les interactions virtuelles." />

              <Section title="Support et Contact" description="Pour toute préoccupation, question ou signalement concernant l'expérience de votre enfant sur Citiizen, n'hésitez pas à contacter notre service client à support@citiizen.fr. Nous sommes là pour assurer un environnement de jeu sûr et accueillant pour tous." />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ForParents;