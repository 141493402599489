import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import api from '../../Api';
import { Helmet } from 'react-helmet-async'
import { useAuth } from '../../AuthContext';
import { toast } from 'react-toastify'; // Import ajouté pour les notifications toast
import DOMPurify from 'dompurify';

const NewArticle = () => {
    const { user } = useAuth();

    // États locaux pour les champs de formulaire
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [content, setContent] = useState('');
    const [isPublic, setIsPublic] = useState(false);
  
    // Fonction pour gérer la soumission du formulaire
    const handleSubmit = async (e) => {
      e.preventDefault(); // Empêcher le comportement de soumission par défaut du formulaire
  
      try {
        // Préparation des données du formulaire
        const formData = {
          title,
          content,
          isPublic,
          shortDescription
        };
  
        // Requête POST à l'API
        const response = await api.post('/news/create', formData);
  
        // Gestion de la réponse ici, par exemple :
        toast.success('Article créé avec succès !');
  
        // Réinitialisation des champs du formulaire ou redirection
      } catch (error) {
        console.error('Erreur lors de la création de l\'article:', error);
        toast.error('Échec de la création de l\'article.');
      }
    };

     // Fonction pour nettoyer le contenu HTML
     const createMarkup = (htmlContent) => {
      return { __html: DOMPurify.sanitize(htmlContent) };
    };

  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <html lang="fr" class="app dark" />
        <title>Citiizen - Admin / Nouvel article</title>
        <link href="https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz&subset=latin,latin-ext" rel="stylesheet"
        type="text/css"></link>
      </Helmet>
      <main class="overflow-hidden site-bg">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="mb-6 lg:justify-between md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">
            Création d'un article
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>

      <form onSubmit={handleSubmit}>
    <div class="grid gap-6 mb-6">
        <div>
            <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titre</label>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Titre de votre article" required />
        </div>
        <div>
            <label for="content" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contenu</label>
            <textarea value={content} required id="content" onChange={(e) => setContent(e.target.value)} rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Contenu de votre article..."></textarea>
        </div>
        <div>
            <label for="shortDescription" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description Courte</label>
            <input type="text" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} id="shortDescription" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description courte" maxLength='255' required />
        </div>
        <label class="inline-flex items-center cursor-pointer">
            <input type="checkbox" checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} class="sr-only peer" name="isPublic" />
            <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Publique</span>
        </label>
    </div>

    <NavLink to="/admin/articles" className="rounded bg-red-600 hover:bg-red-700 text-white p-2 border-2 border-red-500 transition ease-in-out duration-150 font-semibold px-5 py-1">Retour</NavLink>
    <button type="submit" className="rounded bg-blue-700 hover:bg-blue-800 text-white p-2 border-2 border-blue-600 transition ease-in-out duration-150 font-semibold px-5 py-1 ml-5">Envoyer</button>
</form>

<div className="mt-8">
<span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Prévisualisation</span>
              <div
                className="preview relative flex flex-col gap-y-8 overflow-hidden rounded bg-white p-3 shadow dark:bg-gray-800 dark:text-gray-300"
                dangerouslySetInnerHTML={createMarkup(content)}
              />
            </div>

    </div>
      </main>
    </div>
  );
};

export default NewArticle;