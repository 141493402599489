import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import api from '../../Api';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts'
import PlayersTable from '../../Components/Admin/PlayersTable';

const Players = () => {
  const { user } = useAuth();

  const [totalAccounts, setTotalAccounts] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/getAllAccounts/?page=1&search=`);
        setTotalAccounts(response.data.totalAccounts);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    fetchData();
  });

    return (
        <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <html lang="fr" class="app dark" />
        <title>Citiizen - Admin / Gestion Joueurs</title>
      </Helmet>
      <main class="overflow-hidden site-bg">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="mb-6 lg:justify-between md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">
            Gestion des joueurs ({totalAccounts})
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>

      <PlayersTable apiUrl="/getAllAccounts" />
    </div>
      </main>
    </div>
    );
};

export default Players;