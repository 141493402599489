import React from 'react';
import { useAuth } from './AuthContext';
import { useLocation } from 'react-router-dom';
import HeaderOnline from './Components/HeaderOnline';
import HeaderOffline from './Components/HeaderOffline';
import HeaderAdmin from './Components/HeaderAdmin';

function HeaderWrapper() {
  const location = useLocation();
  const { user } = useAuth();

  if(location.pathname.startsWith('/admin') && user) {
    return <HeaderAdmin />;
  }else {
    return user ? <HeaderOnline /> : <HeaderOffline />;
  }
}

export default HeaderWrapper;
