import { NavLink, useLocation } from 'react-router-dom';

const navigation = [
  { name: 'Articles', href: '/community/articles', icon: 'home' },
  { name: 'Gouvernement', href: '/community/government', icon: 'government' },
]

const NavbarCommunity = () => {
  const location = useLocation();

    return (
        <>
        <nav className="hidden lg:flex bg-white shadow dark:bg-gray-800" aria-label="Global">
          <div className="max-w-7xl min-h-[60px] flex items-center justify-center px-4 mx-auto">
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <NavLink to={item.href} key={item.name} className={({ isActive }) => {
                    // Détermine si le lien doit être considéré comme actif pour les items spéciaux comme 'community'
                    const isCommunityActive = item.icon === "home" && location.pathname.startsWith('/community/article');
                    return (isActive || isCommunityActive) ? 
                    "nav-item text-sm font-semibold leading-6 dark:text-gray-200 md:border-b-4 md:border-b-[#eeb425]" : 
                    "nav-item text-sm font-semibold leading-6 dark:text-gray-200";
                }}
                >
                  <i class={'mr-1 hidden navigation-icon '+ item.icon +' lg:inline-flex'}></i>
                  {item.name}
                </NavLink>
              ))}
            </div>
          </div>
        </nav>
    </>
    );
};

export default NavbarCommunity;