import React, { createContext, useContext, useState, useEffect } from 'react';
import api from './Api';
import { useNavigate } from 'react-router-dom'; // Import ajouté pour la redirection
import { toast } from 'react-toastify'; // Import ajouté pour les notifications toast

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Utilisé pour la redirection après authentification
  const [loading, setLoading] = useState(true); // Ajoutez cet état

  const isAuthenticated = () => {
    return !!user; // Convertit `user` en valeur booléenne
  };

  // Définir la fonction de vérification de l'utilisateur pour la réutiliser
  const verifyUser = async (token) => {
    try {
      if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await api.get('/account/fetch');
        setUser(response.data); // Supposons que l'API retourne un objet utilisateur
      }
    } catch (error) {
      console.log(error);
      localStorage.removeItem('token');
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    verifyUser(token).finally(() => setLoading(false));
  }, []);

  const login = async (email, password) => {
    try {
      const response = await api.post('/account/login', { email, password });
      const { token } = response.data;
      if (token) {
        localStorage.setItem('token', token);
        await verifyUser(token); // Utilisez la fonction de vérification ici
        toast.success('Authentification réussie !');
        navigate('/profile'); // Redirige vers le profil de l'utilisateur
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error('Erreur lors de la connexion.');
    }
  };

  const logout = () => {
    setUser(null); // Réinitialise l'utilisateur
    localStorage.removeItem('token');
    api.defaults.headers.common['Authorization'] = '';
    toast.info('Déconnexion réussie.');
    navigate('/'); // Redirige vers la page d'accueil
  };

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, isAuthenticated, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
