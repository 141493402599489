import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Assurez-vous que le chemin est correct

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logout(); // Appelle votre fonction de déconnexion
    navigate('/'); // Redirige vers la page d'accueil après déconnexion
  }, [logout, navigate]);

  return null; // Ce composant n'a pas besoin de rendre quoi que ce soit
};

export default Logout;