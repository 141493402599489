import React from 'react';
import { Helmet } from 'react-helmet-async';

const Section = ({ title, description }) => (
    <div className="w-full flex flex-col gap-y-4 rounded overflow-hidden bg-white pb-3 dark:bg-gray-800 shadow border dark:border-gray-900">
        <div className="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
            <div className="flex flex-col justify-center text-sm">
                <p className="font-semibold text-black dark:text-gray-300">{title}</p>
            </div>
        </div>
        <section className="h-full flex flex-col px-3">
            <p className="mb-2 dark:text-gray-300">{description}</p>
        </section>
    </div>
  );

const RulesAndTerms = () => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <html lang="fr" className="app dark" />
        <title>Citiizen - Règles et CGU</title>
      </Helmet>
      <main className="overflow-hidden site-bg">
        <div className="mx-auto mt-10 grid max-w-7xl grid-cols-12 gap-x-3 gap-y-8 p-6 md:mt-0">
          <div className='col-span-12 flex flex-col gap-y-3'>
            <div className="flex flex-col gap-y-6">
              <div className="mb-6 lg:justify-between md:mb-8">
              <h2
                className="max-w-lg font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none group">
                <span className="inline-block mb-1 sm:mb-4">
                Règles et Conditions Générales d'Utilisation (CGU)
                </span>
                <div
                    className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
            </h2>
              </div>

              <Section title="Cyber Harcèlement" description="Tout comportement constituant du harcèlement envers d'autres joueurs, y compris le cyber harcèlement, est strictement interdit. Cela comprend les menaces, l'intimidation, et tout autre comportement visant à perturber le bien-être d'autrui." />

              <Section title="Triche et Utilisation de Logiciels Externes" description="L'utilisation de logiciels tiers pour modifier ou influencer le gameplay de manière avantageuse est formellement interdite. La triche, y compris l'exploitation de bugs et l'utilisation de logiciels de triche, entraînera des sanctions immédiates, pouvant aller jusqu'à la suspension ou la suppression du compte." />

              <Section title="Altération du Code Source et Modification des Fichiers" description="L'altération du code source de Citiizen, la modification des fichiers du jeu ou le piratage sont strictement interdits et seront traités avec la plus grande sévérité." />

              <Section title="Divulgation d'Informations Personnelles" description="Nous conseillons vivement aux joueurs de ne pas divulguer d'informations personnelles sur les plateformes publiques du jeu, telles que le tchat écrit, les espaces commentaires, ou les messages privés, pour protéger leur vie privée et leur sécurité." />

              <Section title="Utilisation du Tchat Écrit et des Espaces Commentaires" description="Les espaces de communication de Citiizen sont soumis à des règles strictes : le respect mutuel est exigé en tout temps. Les discours haineux, racistes, sexistes ou discriminatoires sous toute forme sont interdits et entraîneront des sanctions." />

              <Section title="Racisme, Discrimination et Contenus Inappropriés" description="Citiizen s'engage à fournir un environnement de jeu sûr et accueillant pour tous. Tout contenu ou comportement raciste, discriminatoire ou inapproprié est strictement interdit et sera sanctionné." />

              <Section title="Respect des Autres Joueurs" description="Le respect des autres joueurs est fondamental dans Citiizen. Les conflits doivent être résolus de manière respectueuse, sans recours à l'insulte ou à l'agression verbale." />

              <Section title="Modification des CGU" description="Citiizen.fr se réserve le droit de modifier ces CGU à tout moment. Les joueurs seront informés de toute modification significative, mais il est de leur responsabilité de rester informés des dernières mises à jour." />

              <Section title="Contact et Support" description="Pour toute question ou préoccupation relative aux règles ou aux CGU, ou pour signaler un comportement ou un contenu inapproprié, veuillez contacter notre équipe de support à support@citiizen.fr." />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default RulesAndTerms;
