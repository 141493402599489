import React, { useState, useEffect } from 'react';
import api from '../../Api';
import { NavLink } from 'react-router-dom';
import { Pagination } from 'flowbite-react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import "@sweetalert2/theme-dark/dark.css";
import { toast } from 'react-toastify';

const PlayersTable = ({ apiUrl }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  
  const removeNews = (item) => {
    const removeSwal = withReactContent(Swal)
    removeSwal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ${item.title} ?`,
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: "Annuler"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        removeNewsById(item);
      } else if (result.isDenied) {
        Swal.fire("Action annulée", "", "info");
      }
    });
  }

  const removeNewsById = async (item) => {
    try {
      // Supposons que l'API attend l'ID de l'utilisateur pour identifier quel enregistrement mettre à jour
      await api.post('/news/remove', {
        id: item.id,
      });
      Swal.fire("Supprimé !", "", "success");
      fetchData();
      // Vous pouvez également recharger les données du joueur ici si nécessaire
    } catch (error) {
      Swal.fire("Erreur lors de la suppression !", "", "error");
    }
  }

  const fetchData = async () => {
    try {
      const response = await api.get(`${apiUrl}?page=${currentPage}&search=${searchTerm}`);
      setData(response.data.news);
      setTotalPages(response.data.totalPages); // Assurez-vous que votre API renvoie le nombre total de pages
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [apiUrl, currentPage, searchTerm]);

  return (
    <div>
    <div className="lg:flex items-center">
    <input
        type="text"
        placeholder="Rechercher..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-3 focus:ring-0 border-4 border-gray-200 rounded dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200 focus:border-[#eeb425]"
      />
      <NavLink to="/admin/articles/create" className="rounded mb-3 bg-green-600 hover:bg-green-700 text-white p-2 border-2 border-green-500 transition ease-in-out duration-150 font-semibold px-5 ml-5" >Ajouter</NavLink>
    </div>
      
      <table className="min-w-full divide-y divide-gray-700 shadow-md sm:rounded-lg text-left rtl:text-right">
        <thead className="bg-gray-900 text-gray-400 ">
          <tr>
          <th scope="col" class="px-6 py-3">
                Title
        </th>
            <th scope="col" class="px-6 py-3">
                Auteur
            </th>
            <th scope="col" class="px-6 py-3">
                Catégorie
            </th>
            <th scope="col" class="px-6 py-3">
                &Eacute;tat
            </th>
            <th scope="col" class="px-6 py-3">
                Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-gray-800 divide-y divide-gray-700 text-gray-300">
          {data.map((item) => (
            <tr key={item.id}>
                <td class="px-6 py-4">
                    {item.title}
                </td>
                <td class="px-6 py-4">
                    {item.authorName}
                </td>
                <td class="px-6 py-4">
                    Mise à jour
                </td>
                <td class="px-6 py-4">
                    {item.isPublic ? <div className='text-green-400'>Publié</div> : <div className='text-yellow-300'>Brouillon</div>}
                </td>
                <td class="flex items-center px-6 py-4">
                    <NavLink to={"/admin/article/" + item.id} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Éditer</NavLink>
                    <button onClick={(e) => removeNews(item)} class="font-medium text-red-600 dark:text-red-500 hover:underline ms-3">Supprimer</button>
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
        previousLabel="Précédent"
        nextLabel="Suivant"
      />
    </div>
  );
};

export default PlayersTable;