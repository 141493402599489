import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

const Home = () => {

    const { t } = useTranslation();

    return (
        <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <html lang="fr" class="app dark" />
        <title>Citiizen - {t('home.title', 'Accueil')}</title>
      </Helmet>
      <main class="overflow-hidden site-bg">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
      <h2
                className="max-w-lg font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none group">
                <span className="inline-block mb-1 sm:mb-4">
                {t('home.latestnews.title', 'Dernières Actualités')}
                </span>
                <div
                    className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
            </h2>
        <p className="dark:text-gray-600 lg:text-sm lg:max-w-md">
          "{t('home.latestnews.subtitle1', 'Les potins les plus frais de Citiizen, juste sous vos yeux !')}"
        </p>
      </div>
      

      <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
        {/* ITEM */}
        <div className="flex">
          <div className="pt-1 mr-6 text-center">
            <div className="px-2 pb-1 mb-1 border-b border-gray-400">
              <p className="text-sm dark:text-gray-200">Fév</p>
            </div>
            <div className="px-2">
              <p className="text-lg font-bold dark:text-gray-200">18</p>
            </div>
          </div>
          <div>
            <div className="mb-2">
              <a
                href="/"
                className="text-xs font-semibold tracking-wide uppercase transition-colors duration-200 text-purple-400 hover:text-purple-800"
                aria-label="Category"
                title="Delevopment"
              >
                {t('news.categories.news', 'Actualité')}
              </a>
            </div>
            <div className="mb-2">
              <NavLink
                to="/community/article"
                aria-label="Article"
                className="inline-block text-2xl font-bold leading-5 dark:text-gray-200 transition-colors duration-200 hover:text-purple-400"
              >
                La chasse aux tickets d'or !
              </NavLink>
            </div>
            <p className="mb-5 dark:text-gray-500">
              Achetez un pack de soutien dans notre boutique pour tenter de trouver un ticket d'or à l'intérieur !
            </p>
            <div className="flex items-center">
              <a href="/" aria-label="Author" title="Auteur" className="mr-3">
                <img
                  src="/profiles/Valentin.png"
                  alt="avatar"
                  className="object-cover w-10 h-10 rounded-full shadow-sm"
                />
              </a>
              <div>
                <a
                  href="/"
                  aria-label="Author"
                  title="Author"
                  className="font-semibold dark:text-gray-400 transition-colors duration-200 hover:text-deep-purple-accent-400"
                >
                  Valentin
                </a>
                <p className="text-sm font-medium leading-4 text-gray-600">
                  Développeur
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* ITEM */}
        <div className="flex">
          <div className="pt-1 mr-6 text-center">
            <div className="px-2 pb-1 mb-1 border-b border-gray-400">
              <p className="text-sm dark:text-gray-200">Fév</p>
            </div>
            <div className="px-2">
              <p className="text-lg font-bold dark:text-gray-200">15</p>
            </div>
          </div>
          <div>
            <div className="mb-2">
              <a
                href="/"
                className="text-xs font-semibold tracking-wide uppercase transition-colors duration-200 text-purple-400 hover:text-purple-800"
                aria-label="Category"
                title="Delevopment"
              >
                {t('news.categories.update', 'Mise à jour')}
              </a>
            </div>
            <div className="mb-2">
              <a
                href="/"
                aria-label="Article"
                className="inline-block text-2xl font-bold leading-5 dark:text-gray-200 transition-colors duration-200 hover:text-purple-400"
              >
                Volants et Frissons: Nouveaux Bolides à l'Horizon !
              </a>
            </div>
            <p className="mb-5 dark:text-gray-500">
              La mise à jour 1.17 est sortie ! Retrouvez de nouveaux véhicules dans votre concessionnaire préféré !
            </p>
            <div className="flex items-center">
              <a href="/" aria-label="Author" title="Author" className="mr-3">
                <img
                  src="/profiles/Valentin.png"
                  alt="avatar"
                  className="object-cover w-10 h-10 rounded-full shadow-sm"
                />
              </a>
              <div>
                <a
                  href="/"
                  aria-label="Author"
                  title="Author"
                  className="font-semibold dark:text-gray-400 transition-colors duration-200 hover:text-deep-purple-accent-400"
                >
                  Valentin
                </a>
                <p className="text-sm font-medium leading-4 text-gray-600">
                  Développeur
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="text-center">
        <NavLink
          to="/community/articles"
          aria-label=""
          className="inline-flex items-center font-semibold transition-colors duration-200 text-purple-400 hover:text-purple-800 my-10"
        >
          {t('home.latestnews.seeall', 'Voir tous les articles')}
          <svg
            className="inline-block w-3 ml-2"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
          </svg>
        </NavLink>
      </div>
    </div>
      </main>
    </div>
    );
};

export default Home;