import { Dialog } from '@headlessui/react'
import { useState } from 'react'
import Modal from './Modal';
import LoginForm from './LoginForm';
import { Dropdown } from 'flowbite-react';
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { NavLink, useLocation } from 'react-router-dom';

const navigation = [
  { name: 'Accueil', href: '/', icon: 'home' },
  { name: 'Communauté', href: '/community/articles', icon: 'community' },
  { name: 'Boutique', href: '/shop', icon: 'shop' }
]

const HeaderOffline = () => {
    const location = useLocation();
    const country = process.env.REACT_APP_SITE_COUNTRY;

    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const checkIsActive = () => {
        return location.pathname.startsWith('/community');
      };

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    return (
        <>
        <div class="max-w-7xl min-h-[60px] px-4 md:flex md:items-center md:justify-between md:mx-auto">
            <div class="flex items-center space-x-3 rtl:space-x-reverse">
                <img src="/logo_house.png" class="h-8 rounded-full" alt="Citiizen Logo" />
                <a href="/">
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Citiizen</span>
                </a>
                <Dropdown className="dark:bg-gray-800" label="" dismissOnClick={false} renderTrigger={() => 
                  <button type="button" class="flex items-center md:gap-2 h-full">
                      <img src={`/icons/flags/${country}.png`} alt="" />
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                      </svg>
                  </button>}>
                  <Dropdown.Item className="dark:text-gray-200 dark:hover:bg-gray-700" as="a" href="https://citiizen.uk/?setLang=en-GB"><img src="/icons/flags/en-GB.png" alt="uk" /></Dropdown.Item>
                  {/* <Dropdown.Item className="dark:text-gray-200 dark:hover:bg-gray-700" as="a" href="https://citiizen.de"><img src="/icons/flags/de.png" alt="de" /></Dropdown.Item> */}
                  <Dropdown.Item className="dark:text-gray-200 dark:hover:bg-gray-700" as="a" href="https://citiizen.fr/?setLang=fr-FR"><img src="/icons/flags/fr-FR.png" alt="fr" /></Dropdown.Item>
                  {/* <Dropdown.Item className="dark:text-gray-200 dark:hover:bg-gray-700" as="a" href="https://citiizen.es"><img src="/icons/flags/es.png" alt="es" /></Dropdown.Item>
                  <Dropdown.Item className="dark:text-gray-200 dark:hover:bg-gray-700" as="a" href="https://citiizen.pt"><img src="/icons/flags/pt.png" alt="pt" /></Dropdown.Item> */}
                </Dropdown>
            </div>
        </div>
      <div className="relative flex h-72 w-full items-center justify-center header-bg">
        <div className="absolute h-full w-full bg-black bg-opacity-50"></div>
        <div class="relative">
          <div class="flex justify-center">
            <div class="text-white font-semibold flex-col md:w-[600px]">
              <p class="hidden text-center text-xl md:block">
              Créez un avatar unique, explorez un monde vibrant, faites des rencontres mémorables.
              </p>
              <div class="flex flex-col items-center justify-center gap-x-6 gap-y-4 md:mt-6 md:flex-row md:gap-y-0">
                
                <button onClick={() => setIsModalOpen(true)} class="rounded-full border-2 border-white px-8 py-2 uppercase transition duration-200 ease-in-out hover:bg-white hover:text-black">Connexion</button>
                    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                        <LoginForm onClose={() => setIsModalOpen(false)} />
                    </Modal>

                <p class="text-sm uppercase text-opacity-80">Ou</p>
                <NavLink data-turbolinks="false" to="/register">
                  <button class="uppercase bg-green-600 bg-opacity-80 px-8 py-2.5 rounded-full transition ease-in-out duration-200 hover:bg-opacity-100">
                  Créer un compte
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
        <nav className="bg-white shadow dark:bg-gray-900" aria-label="Global">
          <div className="max-w-7xl min-h-[60px] flex items-center justify-center px-4 mx-auto">
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
                <NavLink 
                to={item.href} 
                key={item.name} 
                className={({ isActive }) => {
                    // Détermine si le lien doit être considéré comme actif pour les items spéciaux comme 'community'
                    const isCommunityActive = item.icon === "community" && location.pathname.startsWith('/community');
                    return (isActive || isCommunityActive) ? 
                    "nav-item text-sm font-semibold leading-6 dark:text-gray-200 md:border-b-4 md:border-b-[#eeb425]" : 
                    "nav-item text-sm font-semibold leading-6 dark:text-gray-200";
                }}
                >
                <i className={'mr-1 hidden navigation-icon ' + item.icon + ' lg:inline-flex'}></i>
                {item.name}
                </NavLink>
            ))}
            </div>
            <div className="hidden lg:flex items-center">
              <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Log in <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
    </>
    );
};

export default HeaderOffline;