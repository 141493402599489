import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';

const Legals = () => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
          <html lang="fr" className="app dark" />
          <title>Citiizen - Mentions Légales</title>
      </Helmet>
      <main className="overflow-hidden site-bg">
          <div className="mx-auto mt-10 grid max-w-7xl grid-cols-12 gap-x-3 gap-y-8 p-6 md:mt-0">
              <div className='col-span-12 flex flex-col gap-y-3'>
                  <div className="flex flex-col gap-y-6">
                      <div className="mb-6 lg:justify-between md:mb-8">
                      <h2
                            className="max-w-lg font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none group">
                            <span className="inline-block mb-1 sm:mb-4">
                                Mentions Légales
                            </span>
                            <div
                                className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
                        </h2>
                      </div>
                      {/* Éditeur du Site */}
                      <Section title="Éditeur du Site" description="Le site web citiizen.fr est édité et représenté par Monsieur Valentin LAMOTHE, auto-entrepreneur, dont le siège social est situé au 26 rue du Calvaire, 56920 Kerfourn, France.<br/> SIRET : 89332664500015<br/> SIREN : 893326645<br/> TVA intracommunautaire : FR24893326645<br/><br/> Pour toute question ou demande, veuillez contacter : <a href='mailto:support@citiizen.fr' class='text-blue-500 hover:underline'>support@citiizen.fr</a>" />
                      
                      {/* Hébergement */}
                      <Section title="Hébergement" description="Le site est hébergé par OVH.com, RCS Lille Métropole 424 761 419 00045, Code APE 2620Z, N° TVA : FR 22 424 761 419, Siège social : 2 rue Kellermann - 59100 Roubaix - France" />
                      
                      {/* Collecte de Données Personnelles */}
                      <Section title="Collecte de Données Personnelles" description="Le site citiizen.fr collecte des données personnelles liées à l'inscription des utilisateurs.<br/><br/>Pour en savoir plus sur la gestion de vos données personnelles, veuillez consulter notre Politique de Confidentialité ainsi que notre Politique de Cookies." />
                      
                      {/* Propriété Intellectuelle */}
                      <Section title="Propriété Intellectuelle" description="Tous les contenus présents sur le site citiizen.fr sont la propriété de Monsieur Valentin LAMOTHE ou sont utilisés avec l'autorisation des titulaires des droits." />
                      
                      {/* Responsabilité */}
                      <Section title="Responsabilité" description="Monsieur Valentin LAMOTHE s'efforce d'assurer l'exactitude des informations diffusées sur ce site, sans pouvoir garantir l'exactitude, la précision ou l'exhaustivité des informations mises à disposition." />
                  </div>
              </div>
          </div>
      </main>
    </div>
  );
};

const Section = ({ title, description }) => (
  <div className="w-full flex flex-col gap-y-4 rounded overflow-hidden bg-white pb-3 dark:bg-gray-800 shadow border dark:border-gray-900">
      <div className="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
          <div className="flex flex-col justify-center text-sm">
              <p className="font-semibold text-black dark:text-gray-300">{title}</p>
          </div>
      </div>
      <section className="h-full flex flex-col px-3">
          <p className="mb-2 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: description }}></p>
      </section>
  </div>
);

export default Legals;
