import React from 'react'
import { useAuth } from './AuthContext';
import {Navigate, useLocation} from "react-router-dom"

function ProtectedAdminRoute({children}) {
    const { user } = useAuth();
    let location = useLocation();

    if(user == null) {
        return;
    }

    if(user.adminLevel < 1 && location.pathname.startsWith("/admin")) {
        return <Navigate to="/" state={{ from: location}} replace />
    }
    return children;
  }

export default ProtectedAdminRoute;