import React from 'react';
import { Helmet } from 'react-helmet-async';

// Component for each section of the CGV
const Section = ({ title, description }) => (
  <div className="w-full flex flex-col gap-y-4 rounded overflow-hidden bg-white pb-3 dark:bg-gray-800 shadow border dark:border-gray-900">
      <div className="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
          <div className="flex flex-col justify-center text-sm">
              <p className="font-semibold text-black dark:text-gray-300">{title}</p>
          </div>
      </div>
      <section className="h-full flex flex-col px-3">
          <p className="mb-2 dark:text-gray-300">{description}</p>
      </section>
  </div>
);

const TermsOfSale = () => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
          <html lang="fr" className="app dark" />
          <title>Conditions Générales de Vente - Citiizen</title>
      </Helmet>
      <main className="overflow-hidden site-bg">
          <div className="mx-auto mt-10 grid max-w-7xl grid-cols-12 gap-x-3 gap-y-8 p-6 md:mt-0">
              <div className='col-span-12 flex flex-col gap-y-3'>
                  <div className="flex flex-col gap-y-6">
                      <div className="mb-6 lg:justify-between md:mb-8">
                      <h2
                            className="max-w-lg font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none group">
                            <span className="inline-block mb-1 sm:mb-4">
                                Conditions Générales de Vente
                            </span>
                            <div
                                className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
                        </h2>
                      </div>
                      {/* Detailed sections for CGV */}
                      <Section
                        title="1. Introduction"
                        description="Citiizen.fr est un jeu vidéo massivement multijoueur offrant la possibilité d'acheter des pièces d'or via microtransactions. Ces transactions sont sécurisées par Stripe et PayPal."
                      />
                      <Section
                        title="2. Acceptation des CGV"
                        description="Tout achat sur Citiizen.fr implique l'adhésion à ces CGV, qui sont disponibles sur le site à tout moment et prévalent sur tout autre document."
                      />
                      <Section
                        title="3. Conditions de remboursement"
                        description="Les demandes de remboursement pour les pièces d'or achetées doivent être faites dans les 15 jours suivant l'achat et nécessitent que les pièces n'aient pas été utilisées. Les remboursements sont traités au cas par cas par l'équipe de support."
                      />
                      <Section
                        title="4. Litiges"
                        description="En cas de litige résultant d'un chargeback (demande de révocation d'une transaction et remboursement par le détenteur de la carte) initié par la banque de l'utilisateur, le compte de l'utilisateur sera suspendu définitivement."
                      />
                      <Section
                        title="5. Utilisation des services"
                        description="L'utilisateur s'engage à ne pas utiliser les services de manière frauduleuse. Toute utilisation frauduleuse entraînera des sanctions, y compris la suspension du compte."
                      />
                      <Section
                        title="6. Modifications des CGV"
                        description="Citiizen.fr se réserve le droit de modifier les CGV à tout moment. Les modifications entrent en vigueur dès leur publication sur le site."
                      />
                      <Section
                        title="Contact"
                        description="Pour toute question relative aux CGV ou pour toute autre demande, veuillez contacter support@citiizen.fr."
                      />
                  </div>
              </div>
          </div>
      </main>
    </div>
  );
};

export default TermsOfSale;