import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Nettoyer en rétablissant le défilement lorsque le composant est démonté ou le modal est fermé
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div tabindex="-1" aria-hidden="true" class="overflow-y-auto overflow-x-hidden fixed z-50 w-full max-w-md h-auto my-auto mx-auto">
            <div class="relative p-4 h-full w-full max-w-md max-h-full container mx-auto my-auto ">
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-900 w-full">
                    {children}
                </div>
            </div>
        </div> 
       
      </div>
    </>,
    document.getElementById('modal-root')
  );
};

export default Modal;