import React, { useState, useEffect } from 'react';
import api from '../../Api';
import { NavLink } from 'react-router-dom';
import { Pagination } from 'flowbite-react';

const PlayersTable = ({ apiUrl }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`${apiUrl}?page=${currentPage}&search=${searchTerm}`);
        setData(response.data.accounts);
        setTotalPages(response.data.totalPages); // Assurez-vous que votre API renvoie le nombre total de pages
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    fetchData();
  }, [apiUrl, currentPage, searchTerm]);

  return (
    <div>
      <input
        type="text"
        placeholder="Rechercher..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-3 focus:ring-0 border-4 border-gray-200 rounded dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200 focus:border-[#eeb425]"
      />
      <table className="min-w-full divide-y divide-gray-700 shadow-md sm:rounded-lg text-left rtl:text-right">
        <thead className="bg-gray-900 text-gray-400 ">
          <tr>
          <th scope="col" class="px-6 py-3">
                Nom d'utilisateur
        </th>
            <th scope="col" class="px-6 py-3">
                E-mail
            </th>
            <th scope="col" class="px-6 py-3">
                Statut
            </th>
            <th scope="col" class="px-6 py-3">
                Pièces
            </th>
            <th scope="col" class="px-6 py-3">
                Rang
            </th>
            <th scope="col" class="px-6 py-3">
                Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-gray-800 divide-y divide-gray-700 text-gray-300">
          {data.map((item) => (
            <tr key={item.id}>
                <td class="px-6 py-4">
                    {item.username}
                </td>
                <td class="px-6 py-4">
                    {item.email}
                </td>
                <td class="px-6 py-4 text-green-400">
                    En ligne
                </td>
                <td class="px-6 py-4 text-yellow-200">
                    {item.pieces}
                </td>
                <td class="px-6 py-4 text-red-400">
                    {item.adminLevel}
                </td>
                <td class="flex items-center px-6 py-4">
                    <NavLink to={"/admin/player/" + item.username} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Éditer</NavLink>
                    <a href="#" class="font-medium text-red-600 dark:text-red-500 hover:underline ms-3">Sanctionner</a>
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
        previousLabel="Précédent"
        nextLabel="Suivant"
      />
    </div>
  );
};

export default PlayersTable;