import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminHome from '../Pages/Admin/Home';
import AdminTeam from '../Pages/Admin/Team';
import AdminPlayers from '../Pages/Admin/Players';
import AdminPlayer from '../Pages/Admin/Player';
import AdminServers from '../Pages/Admin/Servers';
import AdminArticles from '../Pages/Admin/Articles';
import AdminArticle from '../Pages/Admin/Article';
import AdminNewArticle from '../Pages/Admin/NewArticle';
import ProtectedAdminRoute from '../ProtectedAdminRoute';

const AdminRoutes = () => {
  return (
    <ProtectedAdminRoute>
      <Routes>
        <Route path="home" element={<AdminHome />} />
        <Route path="players" element={<AdminPlayers />} />
        <Route path="player/:username" element={<AdminPlayer />} />
        <Route path="article/:articleId" element={<AdminArticle />} />
        <Route path="team" element={<AdminTeam />} />
        <Route path="articles" element={<AdminArticles />} />
        <Route path="articles/create" element={<AdminNewArticle />} />
        <Route path="servers" element={<AdminServers />} />
      </Routes>
    </ProtectedAdminRoute>
  );
};

export default AdminRoutes;