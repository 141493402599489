import { Helmet } from 'react-helmet-async'
import NavbarCommunity from '../Components/NavbarCommunity';
import { useParams } from 'react-router-dom';
import { useEffect, useState} from 'react';
import api from '../Api';

const Article = () => {
    const { articleId } = useParams(); // Récupère le paramètre d'URL
    const [articleData, setArticleData] = useState(null);

    useEffect(() => {
        const fetchNewsData = async () => {
          try {
            const response = await api.get(`/news/fetchPublicById/${articleId}`);
            console.log(response);
            setArticleData(response.data);
          } catch (error) {
            console.error('Erreur lors du chargement des données de l\'article:', error);
          }
        };
        console.log(articleId);
        
        if (articleId) fetchNewsData();
      }, [articleId]); // Enlever playerData des dépendances pour éviter la réinitialisation

      if (!articleData) return 
      <>
      <Helmet>
            <html lang="fr" class="app dark" />
            <title>Citiizen - Admin / {articleData.title}</title>
          </Helmet>
      <div className="bg-gray-100 dark:bg-gray-900">
      
    <div role="status" class="max-w-sm animate-pulse">
        <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
        <span class="sr-only">Loading...</span>
    </div>
    
    </div></>;
      
    return (
        <>
            <NavbarCommunity />
                <Helmet>
                    <html lang="fr" class="app dark" />
                    <title>Citiizen - {articleData.title} </title>
                </Helmet>
                <main className="overflow-hidden site-bg">
                    <div class="mx-auto mt-10 grid max-w-7xl grid-cols-12 gap-x-3 gap-y-8 p-6 md:mt-0">
                        <div class="col-span-12 rounded space-y-3 md:col-span-3">
                            <div
                                class="relative mt-6 h-24 w-full overflow-hidden rounded border bg-white shadow dark:border-gray-900 dark:bg-gray-800 md:mt-0">
                                <div
                                    class="absolute top-1 right-1 rounded bg-white px-2 text-sm font-semibold dark:bg-gray-700 dark:text-gray-100">
                                    Développeur
                                </div>
                                <div class="h-[65%] w-full profile-bg"
                                    style={{background: 'rgba(0, 0, 0, 0.5) url(\'/header_city_tiny.png\')'}}>
                                </div>
                                <a href="/profile/Valentin"
                                    class="absolute top-4 left-1 drop-shadow-thin profile-icon">
                                    <img class="transition duration-300 ease-in-out hover:scale-105" src="/default.png" alt="" />
                                </a>
                                <p class="text-2xl font-semibold ml-[70px] text-white -mt-[35px]">
                                    {articleData.authorName} 
                                </p>
                                <div class="flex w-full items-center justify-between px-4">
                                    <p class="ml-[57px] text-sm mt-[10px] font-semibold text-gray-500">
                                    </p>
                                    <div class="w-4 h-4 rounded-full mt-2 bg-green-600">
                                    </div>
                                </div>
                            </div>
                            <div
                                class="w-full flex flex-col gap-y-4 rounded overflow-hidden bg-white pb-3 dark:bg-gray-800 shadow border dark:bg-gray-800 dark:border-gray-900">
                                <div class="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
                                    <div
                                        class="max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px] rounded-full relative flex items-center justify-center article-icon">
                                    </div>
                                    <div class="flex flex-col justify-center text-sm">
                                        <p class="font-semibold text-black dark:text-gray-300">Autres articles</p>
                                        <p class="dark:text-gray-500">Nos articles les plus récents</p>
                                    </div>
                                </div>
                                <section class="h-full flex flex-col px-3">
                                    <div class="flex flex-col gap-y-2">
                                        <p class="dark:text-gray-400">
                                            Il n'y a pas d'autre article actuellement
                                        </p>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div class="col-span-12 space-y-4 md:col-span-9">
                            <div
                                class="relative flex flex-col gap-y-8 overflow-hidden rounded bg-white p-3 shadow dark:bg-gray-800 dark:text-gray-300" dangerouslySetInnerHTML={{ __html: articleData.content }}>
                            </div>
                            <div
                                class="w-full flex flex-col gap-y-4 rounded overflow-hidden bg-white pb-3 dark:bg-gray-800 shadow border dark:border-gray-900">
                                <div class="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
                                    <div class="flex flex-col justify-center text-sm">
                                        <p class="font-semibold text-black dark:text-gray-300">Commentaires</p>
                                        <p class="dark:text-gray-500">Les derniers commentaires des citoyens sont affichés juste en bas
                                        </p>
                                    </div> 
                                </div>
                                <section class="h-full flex flex-col px-3">
                                    <div class="px-1 dark:text-gray-200 space-y-[13px]">
                                        <div
                                            class="relative w-full rounded bg-[#f5f5f5] dark:bg-gray-700 p-4 h-[90px] overflow-hidden flex items-center shadow">
                                            <a href="/profile/Jimmy"
                                                class="absolute top-3 left-0 drop-shadow-thin">
                                                <img /*style="image-rendering: pixelated;"*/
                                                    class="transition duration-300 ease-in-out hover:scale-105"
                                                    src="/default.png" width="80px"
                                                    alt="" />
                                            </a>
                                            <div class="flex justify-between ml-[60px] w-full">
                                                <div class="text-sm">
                                                    <a href="/profile/Jimmy"
                                                        class="font-semibold text-[#89cdf0] dark:text-blue-300 hover:underline">
                                                        Jimmy
                                                    </a>
                                                    <p class="block dark:text-gray-200">
                                                        C'est vraiment cool !
                                                    </p>
                                                </div>
                                                <div class="flex gap-x-2">
                                                    <p class="text-xs dark:text-gray-200">
                                                        il y a 3 semaines
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
        </>
    );
};

export default Article;