import React from 'react';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
          <html lang="fr" className="app dark" />
          <title>Politique de Confidentialité - Citiizen</title>
      </Helmet>
      <main className="overflow-hidden site-bg">
          <div className="mx-auto mt-10 grid max-w-7xl grid-cols-12 gap-x-3 gap-y-8 p-6 md:mt-0">
              <div className='col-span-12 flex flex-col gap-y-3'>
                  <div className="flex flex-col gap-y-6">
                      <div className="mb-6 lg:justify-between md:mb-8">
                      <h2
                            className="max-w-lg font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none group">
                            <span className="inline-block mb-1 sm:mb-4">
                                Politique de Confidentialité
                            </span>
                            <div
                                className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
                        </h2>
                      </div>
                      {/* Introduction */}
                      <Section
                        title="1. Introduction"
                        description="Chez Citiizen, nous nous engageons à protéger la vie privée de nos utilisateurs. Cette politique de confidentialité vise à vous informer sur la manière dont nous collectons, utilisons, divulguons et protégeons les données personnelles que vous nous fournissez en utilisant notre site web citiizen.fr."
                      />
                      {/* Données collectées */}
                      <Section
                        title="2. Données collectées"
                        description="Nous collectons les données suivantes : noms d'utilisateurs, adresses e-mail, et mots de passe lors de l'inscription. Nous pouvons également collecter des données sur votre utilisation du site, telles que les pages visitées et les interactions effectuées."
                      />
                      {/* Utilisation des données */}
                      <Section
                        title="3. Utilisation des données"
                        description="Les données collectées sont utilisées pour fournir et améliorer nos services, communiquer avec vous, et assurer la sécurité de notre site."
                      />
                      {/* Partage des données */}
                      <Section
                        title="4. Partage des données"
                        description="Vos données personnelles ne sont pas vendues, échangées, ou partagées avec des tiers sans votre consentement, sauf dans les cas prévus par la loi."
                      />
                      {/* Sécurité */}
                      <Section
                        title="5. Sécurité"
                        description="Nous prenons des mesures de sécurité pour protéger vos données personnelles contre l'accès non autorisé, la modification, la divulgation ou la destruction."
                      />
                      {/* Vos droits */}
                      <Section
                        title="6. Vos droits"
                        description="Vous avez le droit de consulter, modifier, ou supprimer vos données personnelles. Pour exercer ces droits, veuillez nous contacter à support@citiizen.fr."
                      />
                      {/* Modifications de cette politique */}
                      <Section
                        title="7. Modifications de cette politique"
                        description="Nous pouvons mettre à jour notre politique de confidentialité périodiquement. Nous vous informerons de toute modification en publiant la nouvelle politique sur cette page."
                      />
                      {/* Contact */}
                      <Section
                        title="Contact"
                        description="Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter à support@citiizen.fr."
                      />
                  </div>
              </div>
          </div>
      </main>
    </div>
  );
};

const Section = ({ title, description }) => (
  <div className="w-full flex flex-col gap-y-4 rounded overflow-hidden bg-white pb-3 dark:bg-gray-800 shadow border dark:border-gray-900">
      <div className="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
          <div className="flex flex-col justify-center text-sm">
              <p className="font-semibold text-black dark:text-gray-300">{title}</p>
          </div>
      </div>
      <section className="h-full flex flex-col px-3">
          <p className="mb-2 dark:text-gray-300">{description}</p>
      </section>
  </div>
);

export default PrivacyPolicy;
