// Bibliothèques externes
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeModeScript } from 'flowbite-react';

// Composants React et Pages
import Home from './Pages/Home';
import Articles from './Pages/Articles';
import Article from './Pages/Article';
import Legals from './Pages/Legals';
import Government from './Pages/Government';
import Profile from './Pages/Profile';
import Logout from './Components/Logout';
import AdminRoutes from './Routes/AdminRoutes';
import ProtectedRoute from './ProtectedRoute';
import PrivacyPolicy from './Pages/Privacy';

// Contextes et Fonctionnalités
import { AuthProvider } from './AuthContext';

// Composants de Layout
import Footer from './Components/Footer';
import HeaderWrapper from './HeaderWrapper';
import CookiePolicy from './Pages/CookiePolicy';
import TermsOfSale from './Pages/TermsOfSale';
import RulesAndTerms from './Pages/RulesAndTerm';
import ForParents from './Pages/ForParents';
import CustomerService from './Pages/CustomerService';

// Localization
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './i18n';

export default function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const lang = searchParams.get('setLang');
    if (lang) { // Vérifiez que la langue demandée est supportée
      i18n.changeLanguage(lang);
    }
  }, [i18n]);

  return (
    <>
    <AuthProvider>
      <HeaderWrapper />
      <ThemeModeScript />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/legals" element={<Legals />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/termsofsale" element={<TermsOfSale />} />
          <Route path="/rules" element={<RulesAndTerms />} />
          <Route path="/forparents" element={<ForParents />} />
          <Route path="/customerservice" element={<CustomerService />} />
          <Route path="/community/articles" element={<Articles />} />
          <Route path="/community/article/:articleId" element={<Article />} />
          <Route path="/community/government" element={<Government />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/admin/*" element={<ProtectedRoute><AdminRoutes /></ProtectedRoute>} />
      </Routes>
      <ToastContainer theme="dark" />
      <Footer />
    </AuthProvider>
    </>
  )
}