import React from 'react';
import { Helmet } from 'react-helmet-async';

const CookiePolicy = () => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
          <html lang="fr" className="app dark" />
          <title>Politique des Cookies - Citiizen</title>
      </Helmet>
      <main className="overflow-hidden site-bg">
          <div className="mx-auto mt-10 grid max-w-7xl grid-cols-12 gap-x-3 gap-y-8 p-6 md:mt-0">
              <div className='col-span-12 flex flex-col gap-y-3'>
                  <div className="flex flex-col gap-y-6">
                      <div className="mb-6 lg:justify-between md:mb-8">
                      <h2
                            className="max-w-lg font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none group">
                            <span className="inline-block mb-1 sm:mb-4">
                                Politique des Cookies
                            </span>
                            <div
                                className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
                        </h2>
                      </div>
                      {/* Introduction */}
                      <Section
                        title="1. Introduction"
                        description="Citiizen utilise des cookies pour améliorer l'expérience utilisateur sur notre site web et pour maintenir les sessions des utilisateurs. Cette politique explique comment et pourquoi nous utilisons ces cookies."
                      />
                      {/* Qu'est-ce qu'un cookie ? */}
                      <Section
                        title="2. Qu'est-ce qu'un cookie ?"
                        description="Un cookie est un petit fichier texte envoyé à votre navigateur par un site web que vous visitez. Il permet au site de se souvenir de vos actions et préférences (telles que la connexion, la langue, la taille de police et d'autres préférences d'affichage) pendant un certain temps, afin que vous n'ayez pas à les resaisir chaque fois que vous revenez sur le site ou naviguez d'une page à une autre."
                      />
                      {/* Utilisation des cookies */}
                      <Section
                        title="3. Utilisation des cookies"
                        description="Nous utilisons uniquement des cookies essentiels pour maintenir votre session ouverte pendant votre visite sur notre site. Ces cookies ne collectent aucune information personnelle et ne sont pas utilisés à des fins de ciblage publicitaire."
                      />
                      {/* Gestion des cookies */}
                      <Section
                        title="4. Gestion des cookies"
                        description="Vous pouvez contrôler et/ou supprimer les cookies comme vous le souhaitez. Pour plus d'informations, consultez aboutcookies.org. Vous pouvez supprimer tous les cookies déjà présents sur votre ordinateur et configurer la plupart des navigateurs pour les bloquer. Toutefois, si vous faites cela, vous devrez peut-être ajuster manuellement certaines préférences à chaque fois que vous visiterez un site."
                      />
                      {/* Contact */}
                      <Section
                        title="Contact"
                        description="Pour toute question relative à notre politique des cookies, n'hésitez pas à nous contacter à support@citiizen.fr."
                      />
                  </div>
              </div>
          </div>
      </main>
    </div>
  );
};

const Section = ({ title, description }) => (
  <div className="w-full flex flex-col gap-y-4 rounded overflow-hidden bg-white pb-3 dark:bg-gray-800 shadow border dark:border-gray-900">
      <div className="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
          <div className="flex flex-col justify-center text-sm">
              <p className="font-semibold text-black dark:text-gray-300">{title}</p>
          </div>
      </div>
      <section className="h-full flex flex-col px-3">
          <p className="mb-2 dark:text-gray-300">{description}</p>
      </section>
  </div>
);

export default CookiePolicy;