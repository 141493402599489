import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import api from '../../Api';
import { Helmet } from 'react-helmet-async'
import { useAuth } from '../../AuthContext';
import { toast } from 'react-toastify'; // Import ajouté pour les notifications toast

const Player = () => {
  const { username } = useParams(); // Récupère le paramètre d'URL
  const [playerData, setPlayerData] = useState(null);
  const { user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêchez le rechargement de la page
  
    try {
      // Supposons que l'API attend l'ID de l'utilisateur pour identifier quel enregistrement mettre à jour
      await api.post('/account/saveAsAdmin', {
        id: playerData.id,
        ...modifiedData,
      });
      toast.success('Informations mises à jour avec succès !');
      // Vous pouvez également recharger les données du joueur ici si nécessaire
    } catch (error) {
      toast.error('Erreur lors de la mise à jour des informations du joueur !');
      alert('Échec de la mise à jour des informations.');
    }
  };

  const [modifiedData, setModifiedData] = useState({
    username: '',
    email: '',
    pieces: '',
  });

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const response = await api.get(`/account/fetchByUsername/${username}`);
        setPlayerData(response.data);
  
        // Initialisez modifiedData uniquement si playerData est vide (chargement initial) ou si le username change.
        if (!playerData || playerData.username !== username) {
          setModifiedData({
            username: response.data.username || '',
            email: response.data.email || '',
            pieces: response.data.pieces.toString() || '', // Convertissez en chaîne pour le champ de saisie
          });
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données du joueur:', error);
      }
    };
  
    if (username) fetchPlayerData();
  }, [username]); // Enlever playerData des dépendances pour éviter la réinitialisation

  if (!playerData) return 
  <>
  <Helmet>
        <html lang="fr" class="app dark" />
        <title>Citiizen - Admin / {username}</title>
      </Helmet>
  <div className="bg-gray-100 dark:bg-gray-900">
  
<div role="status" class="max-w-sm animate-pulse">
    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
    <span class="sr-only">Loading...</span>
</div>

</div></>;

  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <html lang="fr" class="app dark" />
        <title>Citiizen - Admin / Gestion Joueurs</title>
        <link href="https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz&subset=latin,latin-ext" rel="stylesheet"
        type="text/css"></link>
      </Helmet>
      <main class="overflow-hidden site-bg">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="mb-6 lg:justify-between md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">
            {playerData.username}
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>

      <form onSubmit={handleSubmit}>
    <div class="grid gap-6 mb-6 md:grid-cols-3">
        <div>
            <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom d'utilisateur</label>
            <input type="text" id="username" onChange={(e) => setModifiedData({ ...modifiedData, username: e.target.value })} value={modifiedData.username} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={playerData.username} required />
        </div>
        <div>
            <label for="adminlevel" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Admin Level</label>
            <input type="text" id="adminlevel" disabled class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={playerData.adminLevel} required />
        </div>
        <div>
            <label for="id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Identifiant (ID)</label>
            <input type="number" id="id" disabled class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={playerData.id} required />
        </div>
        <div>
            <label for="pieces" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pièces d'or</label>
            <input type="number" disabled={user.adminLevel < 10} onChange={(e) => setModifiedData({ ...modifiedData, pieces: e.target.value })} id="pieces" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={modifiedData.pieces} required />
        </div>
    </div>
    <div class="mb-6">
        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adresse e-mail</label>
        <input type="email" id="email" onChange={(e) => setModifiedData({ ...modifiedData, email: e.target.value })} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={playerData.email} value={modifiedData.email} required />
    </div> 
    <NavLink to="/admin/players" className="rounded bg-red-600 hover:bg-red-700 text-white p-2 border-2 border-red-500 transition ease-in-out duration-150 font-semibold px-5 py-1">Retour</NavLink>
    <button type="submit" className="rounded bg-blue-700 hover:bg-blue-800 text-white p-2 border-2 border-blue-600 transition ease-in-out duration-150 font-semibold px-5 py-1 ml-5">Valider</button>
</form>

    </div>
      </main>
    </div>
  );
};

export default Player;