import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import api from '../../Api';
import { Helmet } from 'react-helmet-async'
import { useAuth } from '../../AuthContext';
import { toast } from 'react-toastify';

import '../../ckeditor-dark-theme.css';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';

import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Context } from '@ckeditor/ckeditor5-core';
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';


const Article = () => {
  const { articleId } = useParams(); // Récupère le paramètre d'URL
  const [articleData, setArticleData] = useState(null);
  const { user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêchez le rechargement de la page
  
    try {
      // Supposons que l'API attend l'ID de l'utilisateur pour identifier quel enregistrement mettre à jour
      await api.post('/news/save', {
        id: articleData.id,
        ...modifiedData,
      });
      toast.success('Informations mises à jour avec succès !');
      // Vous pouvez également recharger les données du joueur ici si nécessaire
    } catch (error) {
      toast.error('Erreur lors de la mise à jour de l\'article !');
      alert('Échec de la mise à jour des informations.');
    }
  };

  const [modifiedData, setModifiedData] = useState({
    title: '',
    isPublic: '',
    content: '',
    shortDescription: '',
  });

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const response = await api.get(`/news/fetchById/${articleId}`);
        setArticleData(response.data);
  
        // Initialisez modifiedData uniquement si playerData est vide (chargement initial) ou si le username change.
        if (!articleData || articleData.id !== articleId) {
          setModifiedData({
            title: response.data.title || '',
            content: response.data.content || '',
            isPublic: response.data.isPublic || false, // Convertissez en chaîne pour le champ de saisie
            shortDescription: response.data.shortDescription || '',
          });
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données de l\'article:', error);
      }
    };
  
    if (articleId) fetchNewsData();
  }, [articleId]); // Enlever playerData des dépendances pour éviter la réinitialisation

  if (!articleData) return 
  <>
  <Helmet>
        <html lang="fr" class="app dark" />
        <title>Citiizen - Admin / {articleData.title}</title>
      </Helmet>
  <div className="bg-gray-100 dark:bg-gray-900">
  
<div role="status" class="max-w-sm animate-pulse">
    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
    <span class="sr-only">Loading...</span>
</div>

</div></>;

  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <html lang="fr" class="app dark" />
        <title>Citiizen - Admin / {articleData.title}</title>
      </Helmet>
      <main class="overflow-hidden site-bg">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="mb-6 lg:justify-between md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">
            {articleData.title}
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
        </h2>
      </div>

      <form onSubmit={handleSubmit}>
      <div class="grid gap-6 mb-6">
        <div>
            <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Titre</label>
            <input type="text" value={modifiedData.title} onChange={(e) => setModifiedData({ ...modifiedData, title: e.target.value })} id="title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Titre de votre article" required />
        </div>
        <div>
            <label for="content" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contenu</label>
            <textarea value={modifiedData.content} onChange={(e) => setModifiedData({ ...modifiedData, content: e.target.value })} required id="content" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Contenu de votre article..."></textarea>
        </div>
        <div>
            <label for="shortDescription" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description courte</label>
            <input type="text" value={modifiedData.shortDescription} onChange={(e) => setModifiedData({ ...modifiedData, shortDescription: e.target.value })} id="shortDescription" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description courte..." maxLength='255' required />
        </div>
        <label class="inline-flex items-center cursor-pointer">
            <input type="checkbox" checked={modifiedData.isPublic} onChange={(e) => setModifiedData({ ...modifiedData, isPublic: e.target.checked })} class="sr-only peer" name="isPublic" />
            <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Publique</span>
        </label>
    </div>
    
    <NavLink to="/admin/articles" className="rounded bg-red-600 hover:bg-red-700 text-white p-2 border-2 border-red-500 transition ease-in-out duration-150 font-semibold px-5 py-1">Retour</NavLink>
    <button type="submit" className="rounded bg-blue-700 hover:bg-blue-800 text-white p-2 border-2 border-blue-600 transition ease-in-out duration-150 font-semibold px-5 py-1 ml-5">Valider</button>
</form>

    </div>
      </main>
    </div>
  );
};

export default Article;