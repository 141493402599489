import React from 'react';
import { Helmet } from 'react-helmet-async';

const Section = ({ title, description }) => (
    <div className="w-full flex flex-col gap-y-4 rounded overflow-hidden bg-white pb-3 dark:bg-gray-800 shadow border dark:border-gray-900">
        <div className="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
            <div className="flex flex-col justify-center text-sm">
                <p className="font-semibold text-black dark:text-gray-300">{title}</p>
            </div>
        </div>
        <section className="h-full flex flex-col px-3">
            <p className="mb-2 dark:text-gray-300">{description}</p>
        </section>
    </div>
);


const CustomerService = () => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <html lang="fr" className="app dark" />
        <title>Service Client Citiizen</title>
      </Helmet>
      <main className="overflow-hidden site-bg">
        <div className="mx-auto mt-10 grid max-w-7xl grid-cols-12 gap-x-3 gap-y-8 p-6 md:mt-0">
          <div className='col-span-12 flex flex-col gap-y-3'>
            <div className="flex flex-col gap-y-6">
              <div className="mb-6 lg:justify-between md:mb-8">
              <h2
                className="max-w-lg font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none group">
                <span className="inline-block mb-1 sm:mb-4">
                Service Client Citiizen
                </span>
                <div
                    className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
            </h2>
              </div>

              <Section 
                title="Contactez-Nous" 
                description="Pour toute question, assistance ou rapport de bug, notre équipe de service client est là pour vous aider. Veuillez nous contacter via email à support@citiizen.fr. Nous nous efforçons de répondre à toutes les demandes dans les meilleurs délais."
              />

              <Section 
                title="FAQ" 
                description="Avant de nous contacter, nous vous invitons à consulter notre FAQ où vous trouverez des réponses aux questions les plus fréquentes concernant le jeu, les comptes utilisateurs, les transactions et bien plus."
              />

              <Section 
                title="Rapport de Bugs" 
                description="Si vous rencontrez un bug ou un problème technique dans le jeu, veuillez nous fournir une description détaillée du problème ainsi que toute capture d'écran ou vidéo pouvant aider notre équipe technique à identifier et résoudre le problème."
              />

              <Section 
                title="Sécurité et Comportement" 
                description="Pour signaler un comportement inapproprié, du harcèlement ou toute autre préoccupation liée à la sécurité, veuillez contacter le service client avec des détails spécifiques sur l'incident pour permettre une enquête approfondie."
              />

              <Section 
                title="Support Technique" 
                description="Pour l'assistance technique, y compris les problèmes de connexion, les erreurs de paiement ou les problèmes liés au fonctionnement du jeu sur votre appareil, notre équipe de support technique est à votre disposition."
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CustomerService;