import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { useEffect } from 'react';
import Chart from 'react-apexcharts'

const Team = () => {

  const chartTest = {
    options: {
      chart: {
        height: "100%",
        maxWidth: "100%",
        type: "area",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.55,
          opacityTo: 0,
          shade: "#1C64F2",
          gradientToColors: ["#1C64F2"],
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 6,
      },
      grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
          left: 2,
          right: 2,
          top: 0
        },
      },
      xaxis: {
        categories: ['15 Février', '16 Février', '17 Février', '18 Février', '19 Février', '20 Février', '21 Février'],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
    },
    series: [
      {
        name: "Actifs",
        data: [150, 122, 32, 474, 1423, 758],
        color: "#1A56DB",
      },
    ]
  };
  const { user } = useAuth();

    return (
        <div className="bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <html lang="fr" class="app dark" />
        <title>Citiizen - Admin</title>
      </Helmet>
      <main class="overflow-hidden site-bg">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="mb-6 lg:justify-between md:mb-8">
        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-black dark:text-gray-200 sm:text-4xl sm:leading-none md:mb-6 group">
          <span className="inline-block mb-1 sm:mb-4">
            Bienvenue {user.username},
          </span>
          <div className="h-1 ml-auto duration-300 origin-left transform bg-purple-800 scale-x-30 group-hover:scale-x-100" />
        </h2>
        <p className="dark:text-gray-400 lg:text-sm lg:max-w-md">
        <small className='dark:text-gray-500'>Le p'tit mot de La Direction</small><br />
          "Amis ambassadeurs, nous subissons des attaques sur nos serveurs, merci de bien vouloir temporiser auprès de votre communauté."<br />
        </p>
      </div>
      
    <div class="col-span-12 lg:col-span-9 lg:w-[96%]">
        <div class="flex flex-col gap-y-4">
            <div class="flex w-full flex-col gap-y-4 overflow-hidden rounded bg-white pb-3 shadow dark:bg-gray-800">
                <div class="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
                    <div class="max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px] rounded-full relative flex items-center justify-center"
                        style={{backgroundColor: '#e9b124'}}>
                        <img src="/icons/staff.png" alt="" />
                    </div>
                    <div class="flex flex-col justify-center text-sm">
                        <p class="font-semibold text-black dark:text-gray-300">Ambassadeur</p>
                        <p class="dark:text-gray-500">Gérant de Citiizen France</p>
                    </div>
                </div>
                <section class="px-3">
                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                        <div
                            class="relative h-24 w-full overflow-hidden rounded border bg-white dark:border-gray-900 dark:bg-gray-700 md:mt-0">
                            
                            <div class="h-[65%] w-full staff-bg opacity-80"
                                style={{background: 'url(/header_city_tiny.png)'}}>
                            </div>

                            <div
                                class="absolute top-2 right-1 rounded px-2 text-sm font-semibold bg-yellow-300 text-yellow-700">
                                Ambassadeur
                            </div>
                            <div class="absolute top-4 left-0 drop-shadow">
                                <a href="/profile/Valentin">
                                    <img 
                                        class="transition duration-300 ease-in-out hover:scale-105"
                                        src="/default.png"
                                        alt="" width="82" />
                                </a>
                            </div>
                            <p class="text-2xl font-semibold ml-[70px] text-white -mt-[35px] drop-shadow-md">
                                Valentin
                            </p>
                            <div class="flex w-full items-center justify-between px-4">
                                <p class="ml-[57px] text-sm mt-[10px] font-semibold text-gray-500 truncate">
                                    Vouloir c'est pouvoir
                                </p>
                                <div
                                    class="min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px] rounded-full mt-2 flex items-start bg-red-600">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div class="flex w-full flex-col gap-y-4 overflow-hidden rounded bg-white pb-3 shadow dark:bg-gray-800">
                <div class="flex gap-x-2 border-b bg-gray-50 p-3 dark:border-gray-700 dark:bg-gray-900">
                    <div class="max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px] rounded-full relative flex items-center justify-center"
                        style={{backgroundColor: '#a857c6'}}>
                        <img src="/icons/staff_purple.gif" alt="" />
                    </div>
                    <div class="flex flex-col justify-center text-sm">
                        <p class="font-semibold text-black dark:text-gray-300">Modérateurs</p>
                        <p class="dark:text-gray-500">L'équipe de modération de Citiizen France</p>
                    </div>
                </div>
                <section class="px-3">
                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                        <div class="relative h-24 w-full overflow-hidden rounded border bg-white dark:border-gray-900 dark:bg-gray-700 md:mt-0">
                            <div class="h-[65%] w-full staff-bg opacity-80"
                                style={{background: 'url(/header_city_tiny.png)'}}>
                            </div>
                            <div
                                class="absolute top-2 right-1 rounded px-2 text-sm font-semibold bg-purple-600 text-white">
                                Modérateur
                            </div>
                            <div class="absolute top-4 left-0 drop-shadow">
                                <a href="/profile/Valentin">
                                    <img 
                                        class="transition duration-300 ease-in-out hover:scale-105"
                                        src="/default.png"
                                        alt="" width="82" />
                                </a>
                            </div>
                            <p class="text-2xl font-semibold ml-[70px] text-white -mt-[35px] drop-shadow-md">
                                Sandy
                            </p>
                            <div class="flex w-full items-center justify-between px-4">
                                <p class="ml-[57px] text-sm mt-[10px] font-semibold text-gray-500 truncate">
                                    J'aime le soleil
                                </p>
                                <div
                                    class="min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px] rounded-full mt-2 flex items-start bg-green-400">
                                </div>
                            </div>
                        </div>

                        <div class="relative h-24 w-full overflow-hidden rounded border bg-white dark:border-gray-900 dark:bg-gray-700 md:mt-0">
                            <div class="h-[65%] w-full staff-bg opacity-80"
                                style={{background: 'url(/header_city_tiny.png)'}}>
                            </div>
                            <div
                                class="absolute top-2 right-1 rounded px-2 text-sm font-semibold bg-purple-600 text-white">
                                Modérateur
                            </div>
                            <div class="absolute top-4 left-0 drop-shadow">
                                <a href="/profile/Valentin">
                                    <img 
                                        class="transition duration-300 ease-in-out hover:scale-105"
                                        src="/default.png"
                                        alt="" width="82" />
                                </a>
                            </div>
                            <p class="text-2xl font-semibold ml-[70px] text-white -mt-[35px] drop-shadow-md">
                                Jérémy
                            </p>
                            <div class="flex w-full items-center justify-between px-4">
                                <p class="ml-[57px] text-sm mt-[10px] font-semibold text-gray-500 truncate">
                                    J'aime la lune
                                </p>
                                <div
                                    class="min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px] rounded-full mt-2 flex items-start bg-green-400">
                                </div>
                            </div>
                        </div>

                        <div class="relative h-24 w-full overflow-hidden rounded border bg-white dark:border-gray-900 dark:bg-gray-700 md:mt-0">
                            <div class="h-[65%] w-full staff-bg opacity-80"
                                style={{background: 'url(/header_city_tiny.png)'}}>
                            </div>
                            <div
                                class="absolute top-2 right-1 rounded px-2 text-sm font-semibold bg-purple-600 text-white">
                                Modérateur
                            </div>
                            <div class="absolute top-4 left-0 drop-shadow">
                                <a href="/profile/Valentin">
                                    <img 
                                        class="transition duration-300 ease-in-out hover:scale-105"
                                        src="/default.png"
                                        alt="" width="82" />
                                </a>
                            </div>
                            <p class="text-2xl font-semibold ml-[70px] text-white -mt-[35px] drop-shadow-md">
                                Shawn
                            </p>
                            <div class="flex w-full items-center justify-between px-4">
                                <p class="ml-[57px] text-sm mt-[10px] font-semibold text-gray-500 truncate">
                                    J'adore torturer
                                </p>
                                <div
                                    class="min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px] rounded-full mt-2 flex items-start bg-green-400">
                                </div>
                            </div>
                        </div>

                        <div class="relative h-24 w-full overflow-hidden rounded border bg-white dark:border-gray-900 dark:bg-gray-700 md:mt-0">
                            <div class="h-[65%] w-full staff-bg opacity-80"
                                style={{background: 'url(/header_city_tiny.png)'}}>
                            </div>
                            <div
                                class="absolute top-2 right-1 rounded px-2 text-sm font-semibold bg-purple-600 text-white">
                                Modérateur
                            </div>
                            <div class="absolute top-4 left-0 drop-shadow">
                                <a href="/profile/Valentin">
                                    <img 
                                        class="transition duration-300 ease-in-out hover:scale-105"
                                        src="/default.png"
                                        alt="" width="82" />
                                </a>
                            </div>
                            <p class="text-2xl font-semibold ml-[70px] text-white -mt-[35px] drop-shadow-md">
                                Janet
                            </p>
                            <div class="flex w-full items-center justify-between px-4">
                                <p class="ml-[57px] text-sm mt-[10px] font-semibold text-gray-500 truncate">
                                    Bonjour !
                                </p>
                                <div
                                    class="min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px] rounded-full mt-2 flex items-start bg-green-400">
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    </div>
      </main>
    </div>
    );
};

export default Team;