import { Dialog } from '@headlessui/react'
import { useState } from 'react'
import { Dropdown, Modal, Button } from 'flowbite-react';
import axios from 'axios';
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const navigation = [
  { name: 'Accueil', href: '/', icon: 'home' },
  { name: 'Communauté', href: '/community/articles', icon: 'community' },
  { name: 'Boutique', href: '/shop', icon: 'shop' },
  { name: 'Assistance', href: '/support', icon: 'support' },
]

const HeaderOnline = () => {
    const location = useLocation();
    const { user } = useAuth();
    const { logout } = useAuth();

    const country = process.env.REACT_APP_SITE_COUNTRY;

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false);

    const handleLaunchGame = async () => {
      try {
        // Après la réponse AJAX, redirigez vers l'URL de Steam
        window.location.href = 'steam://run/1955780';
      } catch (error) {
        console.error('Erreur lors de la tentative de lancement du jeu:', error);
        // Gérez l'erreur, par exemple en informant l'utilisateur
      }
    };

    return (
        <>
        <div class="max-w-7xl min-h-[60px] px-4 md:flex md:items-center md:justify-between md:mx-auto">
            <div class="flex items-center space-x-3 rtl:space-x-reverse">
                <img src="/logo_house.png" class="h-8 rounded-full" alt="Citiizen Logo" />
                <a href="/">
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Citiizen</span>
                </a>
                <Dropdown className="dark:bg-gray-800" label="" dismissOnClick={false} renderTrigger={() => 
                  <button type="button" class="flex items-center md:gap-2 h-full">
                      <img src={`/icons/flags/${country}.png`} alt="" />
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                      </svg>
                  </button>}>
                  <Dropdown.Item className="dark:text-gray-200 dark:hover:bg-gray-700" as="a" href="/?setLang=en-GB"><img src="/icons/flags/en-GB.png" alt="uk" /></Dropdown.Item>
                  {/* <Dropdown.Item className="dark:text-gray-200 dark:hover:bg-gray-700" as="a" href="https://citiizen.de"><img src="/icons/flags/de.png" alt="de" /></Dropdown.Item> */}
                  <Dropdown.Item className="dark:text-gray-200 dark:hover:bg-gray-700" as="a" href="/?setLang=fr-FR"><img src="/icons/flags/fr-FR.png" alt="fr" /></Dropdown.Item>
                  {/* <Dropdown.Item className="dark:text-gray-200 dark:hover:bg-gray-700" as="a" href="https://citiizen.es"><img src="/icons/flags/es.png" alt="es" /></Dropdown.Item>
                  <Dropdown.Item className="dark:text-gray-200 dark:hover:bg-gray-700" as="a" href="https://citiizen.pt"><img src="/icons/flags/pt.png" alt="pt" /></Dropdown.Item> */}
                </Dropdown>
            </div>

            <div class="flex gap-x-3">
                <div class="relative my-auto">
                    <div class="hidden gap-x-3 md:flex mx-5">
                        <div class="h-[25px] w-[25px] rounded-full nav-gold-icon outline-offset-[3px]" title="Pièces d'or"></div>
                        <div class="dark:text-gray-400">
                            <span class="font-semibold dark:text-white">
                                {user.pieces}
                            </span>
                            <span>&nbsp;pièces d'or</span>
                        </div>
                    </div>
                </div>
                <div  class="relative h-auto md:h-[60px] text-[14px] font-semibold text-gray-700 md:border-b-4 md:border-transparent md:hover:border-b-[#eeb425] transition duration-200 ease-in-out dark:text-gray-200 z-5 !border-none">
                    
                <Dropdown className="dark:bg-gray-800" label="" dismissOnClick={false} renderTrigger={() => 
                  <button x-ref="button" type="button" class="flex items-center md:gap-2 h-full" aria-expanded="false"
                        aria-controls="dropdown-button-1">
                        <img class="h-8 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500 mx-3"
                            src="/default_face.png"
                            alt={`${user.username}`} />
                        <span class="-ml-2">{user.username}</span>
        
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>}>
                  <Dropdown.Item as="div" className="dark:text-gray-200 dark:hover:bg-gray-700">
                    <NavLink to="/profile">
                      Profil
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" className="dark:text-gray-200 dark:hover:bg-gray-700">
                    <NavLink to="/settings">
                      Préférences
                    </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item as="div" className="dark:text-gray-200 dark:hover:bg-gray-700" onClick={logout}>Déconnexion
                  </Dropdown.Item>
                </Dropdown>
                    
        
                    <div x-ref="panel" x-show="open" style={{display: 'none' }}
                        class="absolute left-0 mt-2 rounded bg-white dark:bg-gray-800 shadow whitespace-nowrap overflow-hidden z-10 min-w-[150px]"
                        id="dropdown-button-1">
                        <a href="https://atom.objectretro.net/user/settings/account" target="_self"
                            class="dropdown-item dark:text-gray-200 dark:hover:bg-gray-700 ">
                            Préferences d'utilisateur
                        </a>
                        <button class="dropdown-item dark:text-gray-200 dark:hover:bg-gray-700 w-full text-left">
                            Déconnexion
                        </button>
                        <form id="logout-form" action="https://atom.objectretro.net/logout" method="POST" class="hidden">
                            <input type="hidden" name="_token" value="9jLIIIE0u0XUA9k3VoWdo7UBOrtAUY7khDEI1NeR"
                                autocomplete="off" />
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div className="relative flex h-72 w-full items-center justify-center header-bg">
            <div className="absolute h-full w-full bg-black bg-opacity-50"></div>
            <div class="relative">
                <div class="flex justify-center">
                    <div class="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
                        <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <span class="sr-only">Info</span>
                        <div>
                            <span class="font-medium">Maintenance !</span> Une maintenance est programmée à 16h15, le 19 Février 2024.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <nav className="bg-white shadow dark:bg-gray-900" aria-label="Global">
          <div className="max-w-7xl min-h-[60px] flex items-center justify-center px-4 mx-auto">
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
                <NavLink 
                to={item.href} 
                key={item.name} 
                className={({ isActive }) => {
                    // Détermine si le lien doit être considéré comme actif pour les items spéciaux comme 'community'
                    const isCommunityActive = item.icon === "community" && location.pathname.startsWith('/community');
                    return (isActive || isCommunityActive) ? 
                    "nav-item text-sm font-semibold leading-6 dark:text-gray-200 md:border-b-4 md:border-b-[#eeb425]" : 
                    "nav-item text-sm font-semibold leading-6 dark:text-gray-200";
                }}
                >
                <i className={'mr-1 hidden navigation-icon ' + item.icon + ' lg:inline-flex'}></i>
                {item.name}
                </NavLink>
            ))}

            </div>
            
            <div className="hidden lg:flex items-center px-10">
                <button class=" w-full rounded bg-green-600 hover:bg-green-700 text-white p-2 border-2 border-green-500 transition ease-in-out duration-150 font-semibold px-5 py-1 ml-5" onClick={() => { setOpenModal(true); handleLaunchGame();}}>Jouer</button>
                {user.adminLevel > 5 &&
                    <NavLink to="/admin/home" className=" w-full rounded bg-red-600 hover:bg-red-700 text-white p-2 border-2 border-red-500 transition ease-in-out duration-150 font-semibold px-5 py-1 ml-5">Admin</NavLink>
                }
            </div>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Citiizen</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}

                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Connexion
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>

        <Modal show={openModal} onClose={() => setOpenModal(false)} className='bg-black bg-opacity-50'>
        <Modal.Header className='dark:bg-gray-900'>Installation de Citiizen</Modal.Header>
        <Modal.Body className='dark:bg-gray-900'>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Vous ne possédez pas encore Citiizen ? Aucun souci ! Il vous suffit de télécharger le jeu via Steam.
            </p>
            <p>
              <a href="https://store.steampowered.com/about/" target='_blank' rel="noreferrer">
                <img class="h-auto rounded-lg" src="https://cdn.akamai.steamstatic.com/store/about/social-og.jpg" alt="steam" />
              </a>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className='dark:bg-gray-900'>
          <a class="rounded bg-green-600 hover:bg-green-700 text-white p-2 border-2 border-green-500 transition ease-in-out duration-150 font-semibold px-5 py-1 ml-5" href="https://store.steampowered.com/app/1955780" rel="noreferrer" target="_blank">Installer Citiizen</a>
          <button className="rounded bg-red-600 hover:bg-red-700 text-white p-2 border-2 border-red-500 transition ease-in-out duration-150 font-semibold px-5 py-1 ml-5" onClick={() => setOpenModal(false)}>
            Annuler
          </button>
        </Modal.Footer>
      </Modal>

    </>
    );
};

export default HeaderOnline;